import auth0 from 'auth0-js';
import { SET_TOKEN } from '@/core/services/store/auth.module';


const webAuth = new auth0.WebAuth({
    domain: "itcglobal.us.auth0.com",
    clientID: "W01v7wJaB0s2zmv6eCnxLU7GwR7cR8qA",
    // audience: "https://itcglobal.us.auth0.com/api/v2/",
    audience: "https://portal.itcglobal.com/api/auth/",
    redirectUri: "https://portal.itcglobal.com/callback",
    // redirectUri: "http://localhost:8083/callback",
    responseType: "token id_token",
    scope: "openid profile",
});

let token = {};

const handleAuth = callback => {
    webAuth.parseHash((error, authResult)=>{
        if(authResult && authResult.accessToken && authResult.idToken)
        {
            token.accessToken = authResult.accessToken;
            token.idToken = authResult.idToken;
            token.expiry = new Date().getTime() + authResult.expiresIn * 1000;
            callback();
        }
        else {
            console.log(error);
        }
    })
}

function renewToken() {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          console.log("Renewal ERR::", err)
          reject(err);
        } else {
          // Token renewal success
          resolve(authResult);
        }
      });
    });
  }

const login = () => {
    webAuth.authorize();
  };

const getToken = () => {
    return token;
}

const loggedIn = () => { 
    return token && new Date().getTime() < token.expiry;
  };

  export { handleAuth, login, loggedIn, getToken, renewToken };